// Change website animations
const animation = {
  // make it false to switch off fade-up animation
  animate: true,
  // animation playing duration
  duration: 750,
  // if true, animation plays only once when element comes on screen
  once: false,
};
// Change your display name on tha landing display
const header = {
  name: "Sharif Choudhury",
};
const background = {
  // Options: Snow or Particle
  type: "Snow",
};
// Write a para about yourself here
// To update your image, go to './styles/images.css'
const section2title = "About Me";
const about = {
  paragraph:
    "I'm a hardworking individual who has a passion for problem solving. During my years of studying, I have developed skills in creating Web Applications, Games and Mobile Apps with the latest front-end and backend technologies. On top of this, ever since a young age, I have had an immense passion for Digital Media Design and Graphic Design/Modelling, including Web Design and Digital Video Production. I have acquired bespoke skills in video editing using software such as Sony Vegas and Adobe Premiere Pro, and also design with software such as Photoshop, Illustrator and Blender. My devotion for technology and gaming helps intertwine these skills and helps them work well together.  ",
};
// Edit your skill and the percentage you know about it
// To Add a skill, copy any one below and paste it after the last comma
const skillsBar = [
  {
    name: "HTML5",
    faClass: "fab fa-html5",
  },
  {
    name: "CSS3",
    faClass: "fab fa-css3",
  },
  {
    name: "JavaScript",
    faClass: "fab fa-js",
  },
  {
    name: "Node",
    faClass: "fab fa-node",
  },
  {
    name: "React",
    faClass: "fab fa-react",
  },
  {
    name: "Python/Django",
    faClass: "fab fa-python",
  },
  {
    name: "Java",
    faClass: "fab fa-java",
  },
  {
    name: "Sony Vegas Pro",
    faClass: "",
  },
  {
    name: "Adobe Photoshop",
    faClass: "",
  },
  {
    name: "Adobe Illustrator",
    faClass: "",
  },
  {
    name: "Blender",
    faClass: "",
  },
  {
    name: "Unity",
    faClass: "",
  },
];
// Edit your projects, its name, your skills used to make it, and the url.
// You can omit freely anything if you dont have it
// To Add a Project, copy any one below and paste it after the last comma and increment the id's project number
const section3Title = "Previous Projects and Work";
const projects = [
  {
    // Add image in './styles/images.css' in #project1
    id: "project1",
    name: "Online Newspaper Application with Django",
    skills: ["Django/Python, CSS"],
    url: "https://i.postimg.cc/NQZRqxFR/News-App-Sharif-Choudhury.png",
  },
  {
    // Add image in './styles/images.css' in #project2
    id: "project2",
    name: "FeedbackMachine: Automated Marking of Accurate and Fair Assessments",
    skills: ["Created in ReactJS/JavaScript and Django/Python, with the use of Machine Learning, Natural Language Processing and Sentiment Analysis."],
    url: "https://i.postimg.cc/Y22WfnDB/Feedback-Machine-Sharif-Choudhury.png",
  },
  {
    // Add image in './styles/images.css' in #project3
    id: "project3",
    name: "Full Marks: Online Examination Application",
    skills: ["HTML, CSS, Firebase"],
    url: "https://i.postimg.cc/qpqGdRLH/Full-Marks-Sharif-Choudhury.png",
  },
  {
    // Add image in './styles/images.css' in #project4
    id: "project4",
    name: "Catalyst: Zombie Survival Game",
    skills: ["Unity, C#"],
    url: "https://i.postimg.cc/d0K774ZX/Catalyst-Sharif-Choudhury.png",
  },
  /*{
    // Add image in './styles/images.css' in #project5
    id: "project5",
    name: "Project 5",
    skills: ["HTML, CSS, JS"],
    url: "",
  },
  {
    // Add image in './styles/images.css' in #project6
    id: "project6",
    name: "Project 6",
    skills: ["HTML, CSS, JS"],
    url: "",
  },*/
];
// Edit your Miscellaneous Activities, its name and the url.
// You can omit freely anything if you dont have it
// To Add a Activity, copy any one below and paste it after the last comma and increment the id's Miscellaneous number
const section4Title = "Digital Design and Modelling Work";
const miscellaneous = [
  {
    // Add image in './styles/images.css' in #misc1
    id: "misc1",
    name: "YouTube Thumbnail Design 1",
    skills: ["Design created on Photoshop. Video edited on Adobe Premiere Pro"],
    url: "https://i.postimg.cc/rp90XxZr/Design1-Sharif-Choudhury.png",
  },
  {
    // Add image in './styles/images.css' in #misc2
    id: "misc2",
    name: "YouTube Thumbnail Design 2",
    skills: ["Design created on Adobe Photoshop. Video edited on Sony Vegas Pro"],
    url: "https://i.postimg.cc/mDXPhRsZ/Design2-Sharif-Choudhury.png",
  },
  {
    // Add image in './styles/images.css' in #misc3
    id: "misc3",
    skills: ["Design created on Adobe Photoshop. Video edited on Sony Vegas Pro"],
    name: "YouTube Thumbnail Design 3 ",
    url: "https://i.postimg.cc/7PRCkx9m/design3sharifchoudhury.png",
  },
  {
    // Add image in './styles/images.css' in #misc3
    id: "misc4",
    skills: ["Design created on Adobe Photoshop. Video edited on Sony Vegas Pro"],
    name: "3D Model of Gaming Laptop",
    url: "https://i.postimg.cc/3K0KgwDv/Laptop-Model-Sharif-Choudhury.png",
  },
  {
    // Add image in './styles/images.css' in #misc4
    id: "misc5",
    skills: ["Design created on Adobe Photoshop. Video edited on Sony Vegas Pro"],
    name: "3D Model of Catalyst Zombie Game Sword",
    url: "https://i.postimg.cc/TdrQ14FV/Catalyst-Game-Sw-ord-Sharif-Choudhury.png",
  },
  {
    // Add image in './styles/images.css' in #misc4
    id: "misc6",
    skills: ["Design created on Adobe Photoshop. Video edited on Sony Vegas Pro"],
    name: "Numerous Video Production Projects using Sony Vegas and Adobe Premiere Pro ",
    url: "https://i.postimg.cc/tyBWkb31/Video-Production-Editing-Sharif-Choudhury.png",
  },
];
// Contact form text, and Jotforms link
// To get your own jotform link, go to https://www.jotform.com/
// If you hacve the link already, paste it in the contactUrl below
const section5Title = "Contact Me";
const contact = {
  pitch:
  "If you would like to contact me, please email me at: sharifchoudhury7@gmail.com",
  copyright: "Sharif Choudhury",
  contactUrl: "",
};
// Paste your respective social media links. You can omit any if you dont have it
// Upload your resume in your drive, get the shaareable link and paste it in the resume section
const social = {
  /*github: "https://github.com",
  facebook: "https://facebook.com",
  linkedin: "https://linkedin.com",
  instagram: "https://instagram.com",
  resume: "https://novoresume.com/",*/
};
// Dont change anything here
export {
  animation,
  header,
  background,
  about,
  skillsBar,
  projects,
  miscellaneous,
  contact,
  social,
  section2title,
  section3Title,
  section4Title,
  section5Title,
};
