import React from 'react'
import Skills from '../layouts/Skills'
import { about, skillsBar, section2title } from '../../profile'

const About = () => {
    return (
        <div id="about" className="effect2">
            <div data-aos="zoom-in-up" data-aos-once="true" className="row">
            <div className="col-12 d-none d-md-block offset-md-1 col-md-4 Photo" id="not"></div>
            <div className="col-12 offset-md-1 col-md-6 about">
                <div className="About-title-box">
                <h1 id="About" className="red-line">{section2title}</h1>
                </div>
                <p className="lead about-text">
                    I'm a hardworking individual who has a passion for problem solving. During my years of studying, I have developed skills in creating Web Applications, Games and Mobile Apps with the latest front-end and backend technologies. On top of this, ever since a young age, I have had an immense passion for Digital Media Design and Graphic Design/Modelling, including Web Design and Digital Video Production. I have acquired bespoke skills in video editing using software such as Sony Vegas and Adobe Premiere Pro, and also design with applications such as Photoshop, Illustrator and Blender. My devotion for technology and gaming helps intertwine these skills and assists them to work well together. 
                </p>
            </div>
            </div>
            <div id="Skills"> 
                <div className="row d-flex justify-content-center skills">
                    {skillsBar.map((x) => 
                        <Skills faClass={x.faClass} label={x.name}/>
                    )}
                </div> 
            </div>
        </div>
    )
}

export default About
